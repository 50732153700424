/* Ogólne style */
body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  background-color: #8d2725;
  color: #ebcbc9;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0;
  background-image: url('background.png');
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  overflow-x: hidden;
}

.calendar-container {
  position: relative;
  z-index: 1;
  padding: 0 20px;
  box-sizing: border-box;
  width: 100%;
}

/* Header */
.header {
  padding: 64px 20px;
}

.logo {
  width: 80%;
  max-width: 1000px;
  height: auto;
}

.welcome-message {
  font-size: 32px;
  color: #F9D784;
  margin-top: 10px;
  font-weight: bold;
}

.sponsor-message {
  font-size: 24px;
  font-weight: bold;
  color: #F9D784;
  padding-top: 40px;
  margin-bottom: 20px;
}

.sponsor-logos {
  display: grid;
  grid-template-columns: repeat(4, 2fr);
  justify-items: center;
  align-items: center;
  column-gap: 0;
}

@media (max-width: 900px) {
  .sponsor-logos {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 600px) {
  .sponsor-logos {
    grid-template-columns: repeat(3, 1fr);
    width: 90%;
    margin: 0 auto;
    height: auto;
    gap: 14px;
  }
}

.sponsor-logo {
  width: 100%;
  max-width: 180px;
  height: auto;
  margin: 0 auto;
}

/* Kalendarz */
.calendar-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  margin-top: 40px;
  max-width: 100%;
}

@media (max-width: 1200px) {
  .calendar-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 600px) {
  .calendar-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Kafelki */
.day-card {
  width: 100%;
  max-width: 300px;
  background-color: rgba(0, 0, 0, 0.3);
  border: 3px solid #b58937;
  border-radius: 8px;
  padding: 25px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.day-card.expired {
  background: linear-gradient(
    to bottom,
    rgba(50, 50, 50, 0.301),
    rgba(20, 20, 20, 0.5)
  ); /* Przygaszone, szare tło */
  border: 3px solid #5e5e5e; /* Ciemniejsza, szara ramka */
  opacity: 0.4; /* Zwiększenie efektu przygaszenia */
  color: #5e5e5e; /* Wyblakły kolor tekstu */
  filter: grayscale(50%); /* Efekt szarości */
  transition: all 0.3s ease;
}

.day-card.expired:hover {
  opacity: 0.5; /* Minimalne rozjaśnienie na hover */
}


/* Dzień dzisiejszy (today) */
.day-card.today {
  background-color: rgba(255, 214, 79, 0.3); /* Jasnożółte tło */
  border: 3px solid #f9a825; /* Intensywna złota ramka */
  box-shadow: 0 4px 8px rgba(249, 168, 37, 0.5); /* Subtelny cień */
  animation: pulse 1.5s infinite; /* Pulsowanie */
}

@keyframes pulse {
  0%, 100% {
    box-shadow: 0 12px 20px rgba(249, 168, 37, 0.5);
  }
  50% {
    box-shadow: 0 16px 24px rgba(249, 168, 37, 0.8);
  }
}

.day-card.rainbow-effect {
  background: linear-gradient(to bottom right, #dd0c28 0%, #66eb19 25%, #0e3fe0 50%, #dd0c28 75%, #66eb19 100%);
  background-size: 300% 300%;
  animation: colorscroll 10s linear infinite; /* Animowany gradient dla odblokowanych */
  opacity: 0.9;
  border: 3px solid #fff700;
}

@keyframes colorscroll {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 100%;
  }
}

/* Wygasły kafelek z efektem tęczy */
.day-card.expired.rainbow-effect {
  background: linear-gradient(
    to bottom,
    rgba(50, 50, 50, 0.301),
    rgba(20, 20, 20, 0.5)
  ); /* Przygaszone, szare tło */
  border: 3px solid #5e5e5e; /* Ciemniejsza, szara ramka */
  color: #5e5e5e; /* Wyblakły kolor tekstu */
  filter: grayscale(50%); /* Efekt szarości */
  transition: all 0.3s ease;
}

/* Dni zablokowane (locked) */
.day-card.locked {
  opacity: 0.5;
  cursor: not-allowed;
}

.day-card.locked::before {
  content: "Zablokowane";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.808);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  z-index: 1;
  pointer-events: none;
}

/* Dni odblokowane (unlocked) */
.day-card.unlocked {
  opacity: 1;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.day-card.unlocked:hover {
  transform: scale(1.05);
}

.present-icon {
  width: 60%;
  max-width: 200px;
  height: auto;
  object-fit: cover;
  padding-bottom: 15px;
}

.day-info b {
  font-size: 24px;
  color: #F2A413;
  padding-bottom: 20px;
}

.day-info {
  font-size: 16px;
  font-weight: bold;
  color: #F9D784;
}

a {
  text-decoration: none;
  color: inherit;
}

a:focus, a:active {
  outline: none;
  box-shadow: none;
}

.desc2 {
  font-size: 12px;
  color: #cca19f;
  font-weight: 500;
  margin-top: 25px;
}

.desc3 {
  font-size: 24px;
}